import React from 'react'
import Notifications from 'react-notification-system-redux'
import { connect } from 'react-redux'
import _ from 'lodash'
const currentLocation = window.location.href

const NotificationsSystem = ({ notifications, showNotification }) => (
  <Notifications notifications={showNotification} />
)

const checkMessage = message => {
  const errorMsgList = [
    'Incorrect username or password',
    'The email address not found',
    'You are already registered with ROLZO. Please log in.',
    'Use work email to sign up',
    'user email not varified',
    'User is deactivated',
    "You've been logged out",
    'Password reset link email has been sent to your email address',
    'Password has been reset',
    'Sorry this verification link has expired.',
    'Thank you! Your email address has been confirmed.',
    'Client user Verified',
    "You've successfully activated your account",
    "You've successfully reset password",
    'The token is invalid',
    'The email address was not found',
    'A chauffeur with this phone number already exists',
    'Account already exists',
    'This promotion code already exists.',
    'This promotion code is not applicable for this service.',
    'This promotion code is only applicable for VIP Meet & Greet service.',
    'This promotion code was already used.',
    'This offer is expired',
    'User email not found',
  ]
  const index = _.findIndex(errorMsgList, err => {
    if (err === message) {
      return err
    }
  })
  if (index !== -1) {
    return true
  } else {
    return false
  }
}

const getUnReadNotificationsLength = (notifications, appWebsocket) => {
  var list = []
  _.forEach(notifications, notification => {
    if (checkMessage(notification.message) && !appWebsocket.connected) {
      list.push(notification)
    } else if (currentLocation.includes('pay') || appWebsocket.connected) {
      list.push(notification)
    }
  })
  return list
}

const mapStateToProps = ({ notifications, appWebsocket }) => ({
  notifications,
  showNotification: getUnReadNotificationsLength(notifications, appWebsocket),
})

export default connect(mapStateToProps)(NotificationsSystem)
