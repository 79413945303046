import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import TabsNav from '../TabsNav'
import { getLabelFromValue } from '../../helpers'
import { Badge, Navbar, Row } from 'react-bootstrap'
import { ProtectedRoute } from '../../router/shared/ProtectedRoute'
import AppPage from '../../router/routes/App/AppPage'
import useBottomScrollListener from '../../hooks/useBottomScrollListener'
import {
  defaultBookingStatus,
  defaultCompanyStatus,
  defaultUserStatus,
} from '../../constants'
// import ResponsiveDefaultLayout from '../Responsive/ResponsiveDefaultLayout'
import { SmallUp, SmallDown } from '../Responsive/breakpoints'
import Supportbar from './Supportbar'
import { logout, removeFCMToken, setMessageOpen } from '../../actions'
import { isMobile } from '../../helpers'
import MasquradeBanner from 'components/MasquradeBanner'
import TooltipOnHover from 'components/TooltipOnHover'
import moment from 'moment'
import CarouselComponent from 'components/Events/Carousel'
const isMobileBrowser = isMobile()

const DefaultLayout = React.forwardRef(
  (
    {
      className = '',
      title = '',
      subtitle = '',
      apiBadge = false,
      bookingAdminMobile = false,
      subTitleBadgeGrey = null,
      subTitleBadgeBlack = null,
      children = null,
      // fixedChildren = null,
      addPath = null,
      addPathMapping = null,
      clonePath = null,
      showClose = false,
      showClone = false,
      showBack = false,
      showLogo = false,
      logoSrc = null,
      isBookingView = false,
      isSettingsView = false,
      showMobileTitle = false,
      isMyProfile = false,
      parentPath = '',
      onScrollBottom = () => {},
      showStepper,
      steps = null,
      isSelectingVehicle = false,
      activeStep = null,
      tabs = null,
      isLoggedIn,
      hideSidebar,
      companyId,
      match,
      history,
      onBack = null,
      noLeftPadding,
      noTitle,
      isChatNotification = false,
      company,
      userCompany,
      user,
      userLabel,
      companyLabel,
      isPassengerVip = false,
      isClientUserPage,
      isClientPage,
      isRolzoFirst,
      userPhone,
      companyPhone,
      isAdmin = false,
      fromQuote,
      fromDraft,
      subLink,
      subLinkUrl,
      subLinkImg,
      unreadMessages = 0,
      onToggleUpdate,
      logout,
      isMasqueradeUser,
      currentUser = null,
      isQuoteClient = false,
      showWarningBanner = false,
      bookingIsConfirmed,
      // currentAdminInfo,
      removePageMargin,
      isSupplierList = false,
      isSupplier = false,
      supplierStatus,
      supplierScore,
      supplierRating = 0,
      supplier24HourService = false,
      whatsapp = false,
      updateSupplierRating = () => {},
      update24HourService = () => {},
      onMarkVipPassenger = () => {},
      onMarkBookingImporant = () => {},
      onMarkEscalationBooking = () => {},
      updateWhatsappValue = () => {},
      updateImportantValue = () => {},
      important,
      importantBooking,
      escalationBooking,
      userFirstBooking,
      isPayout = false,
      payoutStatus,
      payoutCompany,
      isPayoutList = false,
      isRatesGrid = false,
      isNewBooking = false,
      isSearchStep = false,
      isEditClientPage = false,
      updatePaymentsTabs = () => {},
      isAdminBookings = false,
      createdBy,
      removeFCMToken,
      userId,
      isAdminList = false,
      isNewEvent = false,
      isAnalytics = false,
      pricingModel,
      rateGridStyle = false,
      isPartnerBooking = false,
    },
    ref
  ) => {
    const isIframeView = window.parent !== window.self

    const [rating, setRating] = useState(supplierRating)
    const [twentyFourHourService, setTwentyFourHourService] = useState(
      supplier24HourService
    )
    const [vipService, setVipService] = useState(isPassengerVip)
    const [importantService, setImportantService] = useState(importantBooking)
    const [escalationService, setEscalationService] = useState(
      escalationBooking
    )
    const [supplierOnWhatsapp, setSupplierOnWhatsapp] = useState(whatsapp)
    const [importantClient, setImportantClient] = useState(important)

    const scrollRef = useRef(null)
    useBottomScrollListener(onScrollBottom, scrollRef, 100, 300)
    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        scrollRef.current.scrollTo({
          top: 0,
        })
      },
    }))
    const tabsToNav = useMemo(
      () =>
        !tabs
          ? null
          : tabs
              .filter(tab => !!tab.hasAccess)
              .map(tab => ({
                ...tab,
                path: `${match.url}${tab.path}`,
              })),
      [tabs, match]
    )

    const onBackClick = e => {
      e.preventDefault()
      if (onBack) {
        onBack()
      } else {
        if (
          history.length <= 2 ||
          history.location.pathname === '/booking/main/new-booking'
        ) {
          history.push('/')
        } else {
          history.goBack()
        }
      }
    }

    useEffect(() => {
      setRating(supplierRating)
    }, [supplierRating])

    useEffect(() => {
      setTwentyFourHourService(supplier24HourService)
    }, [supplier24HourService])

    useEffect(() => {
      setVipService(isPassengerVip)
    }, [isPassengerVip])

    useEffect(() => {
      setImportantService(importantBooking)
    }, [importantBooking])

    useEffect(() => {
      setEscalationService(escalationBooking)
    }, [escalationBooking])

    useEffect(() => {
      setSupplierOnWhatsapp(whatsapp)
    }, [whatsapp])

    useEffect(() => {
      setImportantClient(important)
    }, [important])

    // useEffect(() => {
    //   const originalConsoleLog = console.log
    //   console.log = function(message) {
    //     try {
    //       // Send log messages to React Native using postMessage
    //       window.ReactNativeWebView.postMessage(
    //         JSON.stringify({ type: 'log', message: message })
    //       )
    //     } catch (error) {
    //       // Handle the error, if any
    //     }
    //     // Call the original console.log to display logs in the web console
    //     originalConsoleLog.apply(console, arguments)
    //   }
    // }, [currentUser])

    const oneStar = document.querySelector('#rating-1')
    const twoStars = document.querySelector('#rating-2')
    const threeStars = document.querySelector('#rating-3')
    const fourStars = document.querySelector('#rating-4')
    const fiveStars = document.querySelector('#rating-5')

    if (oneStar && twoStars && threeStars && fourStars && fiveStars) {
      ;[oneStar, twoStars, threeStars, fourStars, fiveStars].forEach(function(
        elem
      ) {
        elem.addEventListener('change', function() {
          if (rating === parseInt(elem.getAttribute('value'))) {
            setRating(null)
            updateSupplierRating(null)
          } else {
            setRating(parseInt(elem.getAttribute('value')))
            updateSupplierRating(parseInt(elem.getAttribute('value')))
          }
        })
      })
    }
    const twentyFourEnabled = document.querySelector('#twenty-four-icon')

    if (twentyFourEnabled) {
      twentyFourEnabled.addEventListener('click', function() {
        setTwentyFourHourService(!twentyFourHourService)
        update24HourService(!twentyFourHourService)
      })
    }

    const vipEnabled = document.querySelector('#vip-icon')

    var vipServiceHandle = function(e) {
      e.preventDefault()
      setVipService(!vipService)
      onMarkVipPassenger()
    }

    if (vipEnabled) {
      vipEnabled.onclick = vipServiceHandle
    }

    const importantEnabled = document.querySelector('#important-icon')

    var importantServiceHandle = function(e) {
      e.preventDefault()
      setImportantService(!importantService)
      onMarkBookingImporant()
    }

    if (importantEnabled) {
      importantEnabled.onclick = importantServiceHandle
    }

    const escalationEnabled = document.querySelector('#escalation-icon')

    var escalationServiceHandle = function(e) {
      e.preventDefault()
      setEscalationService(!escalationService)
      onMarkEscalationBooking()
    }

    if (escalationEnabled) {
      escalationEnabled.onclick = escalationServiceHandle
    }

    const whatsappEnabled = document.querySelector('#whatsapp-icon')

    if (whatsappEnabled) {
      whatsappEnabled.addEventListener('click', function() {
        setSupplierOnWhatsapp(!supplierOnWhatsapp)
        updateWhatsappValue(!supplierOnWhatsapp)
      })
    }

    const importantClientEnabled = document.querySelector(
      '#important-client-icon'
    )

    if (importantClientEnabled) {
      importantClientEnabled.addEventListener('click', function() {
        setImportantClient(!importantClient)
        updateImportantValue(!importantClient)
      })
    }
    const clientLogOut = async () => {
      try {
        //remove FCM token for react native clients
        if (window.ReactNativeWebView) {
          await removeFCMToken(userId, navigator.userAgent)
        }
        await logout()
      } catch (error) {
        console.log('clientLogOut error', error)
      }
    }

    const renderTabs = () => {
      if (!tabs || !tabs.length) {
        return
      }

      if (!tabsToNav || !tabsToNav.length) {
        return <Redirect to='/' />
      }

      //mobile view account
      if (isSettingsView && isMobileBrowser) {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {company.isRolzoFirstMember && title === 'Account' ? (
                <div
                  className='rolzo-first-label-client'
                  style={{
                    textTransform: 'none',
                    width: '6.3rem',
                    marginBottom: '1.2rem',
                  }}
                >
                  <span>ROLZO First </span>
                  <img
                    src='/images/icons/rolzo-first-star.png'
                    alt=''
                    style={{
                      height: '13px',
                      width: '13px',
                      marginTop: '0.09rem',
                      marginLeft: '0.2rem',
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className='light-tabs-content'
              style={
                title === 'Bookings' || title === 'Bookings POC' || isAnalytics
                  ? { maxWidth: '100%' }
                  : title === 'Quotes'
                  ? { maxWidth: '1200px' }
                  : {}
              }
            >
              <Switch>
                <Route
                  exact
                  path={`${match.path}`}
                  render={() => (
                    <>
                      <div className='useless-class'>
                        {tabs.map(({ title = '', path, key }, index) => {
                          if (path) {
                            return (
                              <Row
                                className={'settings-row'}
                                onClick={() => {
                                  history.push(`/settings/main${path}`)
                                }}
                              >
                                <div className={'settings-option'} key={path}>
                                  {title}
                                  <img
                                    src={`/images/icons/not_blurry_arrow.png`}
                                    alt=''
                                  />
                                </div>
                              </Row>
                            )
                          }
                          return (
                            <Row className='settings-row'>
                              <div
                                className='settings-option'
                                key={key}
                                onClick={() => {
                                  history.push(`/settings/main${path}`)
                                }}
                                eventKey={key}
                              >
                                {title}
                                <img
                                  src={`/images/icons/not_blurry_arrow.png`}
                                  alt=''
                                />
                              </div>
                            </Row>
                          )
                        })}
                        <div
                          className='settings-logout-img-wrapper'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '5rem',
                            color: '#a79779',
                            fontSize: '16px',
                          }}
                          onClick={clientLogOut}
                        >
                          <img
                            style={{ marginRight: '0.5em', height: '1.3125em' }}
                            src='/images/icons/icon-mobile-logout.png'
                            alt=''
                          />
                          {'Log out'}
                        </div>
                      </div>
                    </>
                  )}
                />

                {tabs.map(route => {
                  const { path, isPrivate, hasAccess } = route
                  return (
                    <ProtectedRoute
                      key={path}
                      isLoggedIn={isLoggedIn}
                      isPrivate={isPrivate}
                      path={`${match.url}${path}`}
                      exact={route.exact}
                      hasAccess={hasAccess}
                      isAccountView={title === 'Account'}
                      component={() => (
                        <AppPage
                          isTabRoute
                          isLoggedIn={isLoggedIn}
                          parentPath={path}
                          scrollRef={scrollRef}
                          updatePaymentsTabs={
                            updatePaymentsTabs ? updatePaymentsTabs : null
                          }
                          {...route}
                        />
                      )}
                    />
                  )
                })}
              </Switch>
            </div>
          </>
        )
      }

      return (
        <>
          <TabsNav
            align='start'
            tabs={tabsToNav}
            sectionTabs={
              company &&
              company.useInternalBookingId &&
              (title === 'Bookings' || title === 'Quotes')
                ? true
                : false
            }
            isAdminBookings={isAdminBookings}
            className={`${
              title === 'Bookings' && isMobileBrowser
                ? 'light-tabs-mobile'
                : 'light-tabs'
            }`}
          />
          <div
            className='light-tabs-content'
            style={
              title === 'Bookings' || title === 'Bookings POC' || isAnalytics
                ? { maxWidth: '100%' }
                : title === 'Quotes'
                ? { maxWidth: '1200px' }
                : {}
            }
          >
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                render={() => (
                  <Redirect replace to={`${match.url}${tabs[0].path}`} />
                )}
              />
              {tabs.map(route => {
                const { path, isPrivate, hasAccess } = route
                return (
                  <ProtectedRoute
                    key={path}
                    isLoggedIn={isLoggedIn}
                    isPrivate={isPrivate}
                    path={`${match.url}${path}`}
                    exact={route.exact}
                    hasAccess={hasAccess}
                    component={() => (
                      <AppPage
                        isTabRoute
                        isLoggedIn={isLoggedIn}
                        parentPath={path}
                        scrollRef={scrollRef}
                        isAccountView={title === 'Account'}
                        updatePaymentsTabs={
                          updatePaymentsTabs ? updatePaymentsTabs : null
                        }
                        {...route}
                      />
                    )}
                  />
                )
              })}
            </Switch>
          </div>
        </>
      )
    }

    const renderStepper = () => {
      if (!showStepper || isNewBooking) {
        return
      }

      return (
        <>
          {/* <SmallUp>
            <Stepper isQuoteClient={isQuoteClient}>
              {steps.map((step, index) => {
                const { title, indicator } = step
                return (
                  <Step
                    key={String(index)}
                    title={title}
                    indicator={indicator}
                    active={index === activeStep}
                    isQuoteClient={isQuoteClient}
                  />
                )
              })}
            </Stepper>
          </SmallUp> */}
        </>
      )
    }

    const mobileSidebarToggle = e => {
      e.preventDefault()
      document.documentElement.classList.toggle('nav-open')
      let node = document.createElement('div')
      node.id = 'bodyClick'
      node.onclick = function() {
        this.parentElement.removeChild(this)
        document.documentElement.classList.toggle('nav-open')
      }
      document.body.appendChild(node)
    }
    const companyLogo =
      company &&
      (company.logo ? (
        <div className='company-logo float-left has-logo'>
          <img src={company.logo} alt='' />
        </div>
      ) : (
        <div className='company-sm-logo float-left'>
          {company.name.substring(0, 1)}
        </div>
      ))

    return (
      <>
        {!hideSidebar &&
        !!companyId &&
        // This is a temp fix, till i have time to look into it
        document.getElementById('app-page') &&
        document.getElementById('app-page').hasChildNodes() ? (
          <Supportbar onBack={onBack} isIframeView={isIframeView} />
        ) : (
          ''
        )}
        <div
          ref={scrollRef}
          className={`scrollable-page-content ${className || ''} ${
            hideSidebar ? 'scrollable-page-content-without-supportbar' : ''
          } ${noTitle ? 'no-title' : ''} ${
            removePageMargin ? 'lightThemeHeight' : ''
          } ${activeStep === 1 && isNewBooking ? 'stepTwoHeight' : ''}`}
          style={
            isPayoutList
              ? { height: 'auto' }
              : title === 'ROLZO First' && isMobileBrowser
              ? { overflow: 'scroll' }
              : {}
          }
        >
          {!isPayoutList && !isNewBooking ? (
            // prettier-ignore
            <Navbar
              className={`${showStepper && isSelectingVehicle ? 'navbar-without-stepper' : '' } ${isSettingsView && isMobileBrowser ? 'settings-page' : '' }
                          ${isChatNotification ? 'h-fit': ''}
              
              `}
            >
              <Navbar.Brand
                className={`${showBack || showClose || showStepper ? 'w-100' : ''
                  }`}
              >
                {
                  <>
                    <header
                      className={
                        !isMobileBrowser && isAdmin
                          ? `${'withoutBadge'} ${noTitle ? 'no-title-header' : ''
                          }`
                          : `${noTitle ? 'no-title-header' : ''
                          } ${bookingAdminMobile &&
                          isMobileBrowser &&
                          'd-flex'}
                          ${isMobileBrowser && isSettingsView ? 'mt-0': ''}
                          ${isChatNotification ? 'mt-0': ''}
                          `
                      }
                      style={removePageMargin ? { paddingLeft: '1%', paddingTop: '15px' } : rateGridStyle ? { paddingTop: '0px' } : { paddingTop: '15px' }}
                    >
                      {!!showBack && (
                        <div
                          className={`
                              ${noTitle ? 'no-title-back-btn-wrap' : ''} 
                              ${bookingAdminMobile && isMobileBrowser && 'align-self-end'}
                          `}
                        >
                          <SmallDown>
                            <a
                              id='back-btn'
                              href='/'
                              onClick={onBackClick}
                              className='layout-btn back-btn step-back-btn'
                            >
                              <img src={`/images/icons/step_back.png`} alt='' />
                            </a>
                          </SmallDown>
                          {title && noTitle && (
                            <>
                              <h1
                                className={`
                                ${ isAdmin ? 'page-title-admin' : 'page-title' }
                                `}
                                style={
                                  title === 'Support'
                                    ? { marginLeft: '1rem' }
                                    : title === 'External Apis'
                                      ? { margin: '0.4rem' }
                                      : {}
                                }
                              >
                                {title}
                              </h1>
                            </>
                          )}
                          {isMobileBrowser && !!userCompany && !!user && (
                            <div
                              className={
                                bookingAdminMobile && isMobileBrowser
                                  ? 'company-info-title-admin'
                                  : isAdmin
                                    ? 'company-info-title mt-0'
                                    : 'company-info-title'
                              }
                              style={
                                bookingAdminMobile && isMobileBrowser
                                  ? { flexDirection: 'column' }
                                  : {}
                              }
                            >
                              <h1 style={{ marginLeft: '7rem' }}>
                                {userCompany}
                              </h1>
                              <h4 style={{ marginLeft: '8rem' }}>{user}</h4>
                            </div>
                          )}
                          {bookingAdminMobile && isMobileBrowser && (
                            <span className={apiBadge || isPartnerBooking ? 'd-flex' : ''}>
                              {title}{' '}
                              {isPartnerBooking ? (
                                 <div className='partnerBadgeBooking ml-2'>P</div>
                              ): <></>}
                              {apiBadge && (
                                <div className='apiBadgeBookingMobile ml-2'>
                                  API
                                </div>
                              )}
                              {(importantBooking || userFirstBooking) && (
                                <img
                                  src='/images/icons/icon-important.png'
                                  className='important-booking-badge'
                                  alt=''
                                />
                              )}
                            </span>
                          )}
                        </div>
                      )}
                      {!noTitle ? (
                        <>
                          {!showStepper ? (
                            <>
                              <div
                                style={
                                  isAdmin && title && title.includes('Booking')
                                    ? {
                                      fontSize: '1.5rem',
                                      marginBottom: '0px',
                                    }
                                    : {}
                                }
                                className={
                                  isAdmin
                                    ? `page-title-admin ${isChatNotification
                                      ? 'chatnotification-page-title'
                                      : ''
                                    }`
                                    : isEditClientPage ? 'edit-client-page-title' : bookingAdminMobile
                                      ? 'page-title-booking'
                                      : `page-title ${isChatNotification ? 'chatnotification-page-title' : '' } 
                                                    ${ isSettingsView && isMobileBrowser ? 'account-header' : '' }
                                                  `
                                }
                              >
                                {!!showLogo && (
                                  <>
                                    {!!logoSrc ? (
                                      <img
                                        className='layout-logo'
                                        src={logoSrc}
                                        alt=''
                                      />
                                    ) : (
                                      <div className='layout-logo'>
                                        {title.substring(0, 1)}
                                      </div>
                                    )}
                                  </>
                                )}
                                {/* {!!isSettingsView && (
                                  <SmallDown>
                                    <div
                                      className='settings-logout-img-wrapper'
                                      onClick={clientLogOut}
                                    >
                                      <img
                                        src='/images/icons/logout_mobile.png'
                                        alt=''
                                      />
                                    </div>
                                  </SmallDown>
                                )} */}
                                {/* {console.log("currentUser", currentUser)} */}
                                {/* {window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'log', message: currentUser }))} */}
                                {currentUser && (
                                  <SmallDown>
                                    <div className='settings-logout-img-wrapper' style={{display:'flex'}}>
                                      {company && isMobileBrowser && company.type !== 'supplier' && ((company.industry === 'Lifestyle Management' || company.industry === 'Travel') && ((pricingModel && (pricingModel.commissionable || pricingModel.netDiscount)) || (company.corporateBenefits && company.corporateBenefits.defaultBenefits && (company.corporateBenefits.defaultBenefits.commissionWeight || company.corporateBenefits.defaultBenefits.discountWeight)) || company.isRolzoFirstMember)) ? (
                                        <div className='rolzo-first-label-mobile'  onClick={() => history.push('/settings/main/rolzoFirst')}>
                                          <img
                                              src='/images/icons/rolzo-first-star-mobile.png'
                                              alt=''
                                              style={{
                                                height: '1.563rem',
                                                width: '1.563rem',
                                              }}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )} 
                                       {company && company.type === 'supplier' ? (
                                          <Link to='/partner/auth'
                                            className='switch-client-btn-mob'
                                          >
                                            <span>Switch to partner</span>
                                            <img src='/images/icons/switch-gold.png'></img>
                                          </Link>
                                        ) : (
                                          <></>
                                       )}
                                      {currentUser.company &&
                                        currentUser.company.logo ? (
                                        <div>
                                          <img
                                            className='company-logo-top'
                                            src={company.logo}
                                            alt=''
                                            onClick={() => {
                                              isMobileBrowser ? history.push('/settings/main')
                                              : history.push('/settings/main/general')
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className='profile-default-logo-top'
                                          onClick={() => {
                                            isMobileBrowser ? history.push('/settings/main')
                                            : history.push('/settings/main/general')
                                          }}
                                        >
                                          {currentUser.firstName.substring(0, 1)}
                                          {currentUser.lastName
                                            ? currentUser.lastName.substring(0, 1)
                                            : ''}
                                        </div>
                                      )}
                                    </div>
                                  </SmallDown>
                                )}
                                {!isBookingView && !showMobileTitle ? (
                                  <>
                                    {isSettingsView && isMobileBrowser ? (                
                                      <a
                                        id='back-btn'
                                        href='/'
                                        onClick={onBackClick}
                                        className='layout-btn back-btn'
                                      >
                                        <img src={`/images/icons/step_back.png`} alt='' />
                                      </a>
                                    ) : (
                                      <></>  
                                    )}
                                    {!!isAdmin && !!userCompany && !!user ? (
                                      <>
                                        {isAdmin &&
                                          title &&
                                          title.includes('Booking') ? (
                                            <>
                                          <div className='d-flex justify-content-between align-items-center'>
                                            {!isMobileBrowser && (
                                              <>
                                                {' '}
                                                <span
                                                  className={
                                                    apiBadge ||
                                                      importantBooking ||
                                                      userFirstBooking ||
                                                      isPartnerBooking
                                                      ? fromQuote
                                                        ? 'd-flex flex-wrap'
                                                        : 'd-flex'
                                                      : ''
                                                  }
                                                >
                                                  {`${title} | ${userCompany}`} 
                                                  {/* {isRolzoFirst && isRolzoFirst===true ? 
                                                    <Badge
                                                      href='#'
                                                      variant={`rolzo-first badge-outlined py-1 ml-1 mr-1`}
                                                      style={{
                                                        display: 'inline-block',
                                                        marginBottom: '0.4rem',
                                                      }}
                                                    >
                                                      First
                                                    </Badge> : <></>} */}
                                                  {` | ${user}`}{' '}
                                                  {userLabel && (
                                                    <Badge
                                                      href='#'
                                                      variant={`${userLabel}-user badge-outlined py-1`}
                                                      style={{
                                                        display: 'inline-block',
                                                        marginLeft: '0.5rem',
                                                        marginBottom: '0.4rem',
                                                      }}
                                                    >
                                                      {getLabelFromValue(defaultUserStatus, userLabel)}
                                                    </Badge>
                                                  )}
                                                  {isPartnerBooking ? (
                                                    <div className='partnerBadgeBooking ml-2'>P</div>
                                                  ): <></>}
                                                  {isRolzoFirst && isRolzoFirst===true ? 
                                                    <img
                                                      src='/images/icons/rolzo-first-icon.png'
                                                      alt=''
                                                      className='important-booking-badge ml-2'
                                                    >
                                                    </img> : <></>}
                                                  <img
                                                    style={{ width: '28px', height: '23px', marginLeft: '10px', marginBottom: '0.3rem', cursor: 'pointer' }}
                                                    id='vip-icon'
                                                    src={vipService ? '/images/icons/icon-vip.png' : '/images/icons/icon-vip-disabled.png'}
                                                    alt= ''
                                                  />
                                                  {apiBadge && (
                                                    <div className='apiBadgeBooking ml-2'>
                                                      API
                                                    </div>
                                                  )}
                                                  <img
                                                    src={(importantService || userFirstBooking) ? '/images/icons/icon-important.png' : '/images/icons/icon-important-disabled.png'}
                                                    id='important-icon'
                                                    style={{ cursor: 'pointer' }}
                                                    className='important-booking-badge'
                                                    alt=''
                                                  />
                                                  <img
                                                    src={escalationService ? '/images/icons/escalation-icon.png' : '/images/icons/escalation-icon-disabled.png'}
                                                    id='escalation-icon'
                                                    style={{ cursor: 'pointer' }}
                                                    className='important-booking-badge mr-2'
                                                    alt=''
                                                  />
                                                </span>
                                                <span
                                                  style={isRolzoFirst ? { fontSize: '13px' } : { fontSize: '16px'}}
                                                >
                                                  {`User: `}
                                                  <a href={`tel:${userPhone}`} className='ClickOnCall'>
                                                    {userPhone}
                                                  </a>
                                                  {` | Company: `} <a href={`tel:${companyPhone}`} className='ClickOnCall'>
                                                    {companyPhone}
                                                  </a>
                                                </span>

                                              </>
                                            )}
                                          </div>
                                          {fromQuote && (
                                                    <div
                                                      style={{
                                                        fontSize: '16px',
                                                        flexBasis: '10%',
                                                      }}
                                                    >
                                                      From Quote
                                                    </div>
                                                  )}
                                                  {fromDraft && (
                                                    <div
                                                      style={{
                                                        fontSize: '16px',
                                                        flexBasis: '100%',
                                                      }}
                                                    >
                                                      From Draft
                                                    </div>
                                                  )}
                                            </>
                                        ) : (
                                          <>{title}</>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {isSupplier && supplierStatus ? (
                                          <>
                                            {`${title}`}

                                            <Badge
                                              href='#'
                                              variant={`${supplierStatus.value}-admin badge-outlined py-1 p1-2 mb-2`}
                                              style={{
                                                display: 'inline-block',
                                                marginLeft: '1.3rem',
                                              }}
                                            >
                                              {supplierStatus.label}
                                            </Badge>
                                            <div class="partner-star-container">
                                              <div class="rating">
                                                <input type="checkbox" name="rating" id="rating-5" value={5} checked={rating === 5} />
                                                <label for="rating-5"></label>
                                                <input type="checkbox" name="rating" id="rating-4" value={4} checked={rating === 4} />
                                                <label for="rating-4"></label>
                                                <input type="checkbox" name="rating" id="rating-3" value={3} checked={rating === 3} />
                                                <label for="rating-3"></label>
                                                <input type="checkbox" name="rating" id="rating-2" value={2} checked={rating === 2} />
                                                <label for="rating-2"></label>
                                                <input type="checkbox" name="rating" id="rating-1" value={1} checked={rating === 1} />
                                                <label for="rating-1"></label>
                                              </div>
                                            </div>
                                            <img id='twenty-four-icon' class='supplier-icons'alt= ''
                                              src={twentyFourHourService ? '/images/icons/twenty-four-hour-icon.png' :
                                                '/images/icons/twenty-four-hour-icon-disabled.png' }
                                            />
                                            <img id='whatsapp-icon' class='supplier-icons'alt= ''
                                              src={supplierOnWhatsapp ? '/images/icons/whatsapp-icon.png' :
                                                '/images/icons/whatsapp-icon-disabled.png'}
                                            />
                                          </>
                                        ) : isPayout ? (
                                          <div className='d-flex flex-column'>
                                            <div>
                                            {`Payout #${title}`}
                                            <Badge
                                              href='#'
                                              variant={`${payoutStatus}-admin badge-outlined py-1 p1-2 mb-2`}
                                              style={{
                                                display: 'inline-block',
                                                marginLeft: '1.3rem',
                                              }}
                                            >
                                              {payoutStatus === 'paid' ? 'paid' : 
                                               payoutStatus === 'partially_paid' ? 'partially paid': 
                                               'pending'}
                                            </Badge>
                                            </div>
                                          {payoutCompany? <span style={{ fontSize: '18px' }}>{payoutCompany}</span> : <></>}
                                          </div>
                                        ) : (
                                          <> 
                                            {/* client side pages title */}
                                            <span style={isMobileBrowser ? isSettingsView || isChatNotification ? { fontSize:'16px', paddingBottom: '0', display: 'flex', justifyContent: 'center' } : { fontSize:'24px', display: 'flex' } : {} }> 
                                              {`${title}`} 
                                              {title === 'ROLZO First' && isMobileBrowser && company.isRolzoFirstMember ? (
                                                <img
                                                  src='/images/icons/rolzo-first-success.png'
                                                  alt=''
                                                  style={{ height: '15px', width: '15px', marginLeft: '0.5rem' }}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </span> 
                                            {isClientUserPage && userLabel && (
                                              <Badge
                                                href='#'
                                                variant={`${userLabel}-user badge-outlined py-1 mb-1`}
                                                style={{
                                                  display: 'inline-block',
                                                  marginLeft: '0.5rem',
                                                }}
                                              >
                                                {getLabelFromValue(defaultUserStatus, userLabel)}
                                              </Badge>
                                            )}
                                            {/* {isClientPage && isRolzoFirst && (
                                              <Badge
                                              href='#'
                                              variant={`rolzo-first badge-outlined py-1 mb-1`}
                                              style={{
                                                display: 'inline-block',
                                                marginLeft: '1rem',
                                              }}
                                            >
                                              First
                                            </Badge>
                                            )} */}
                                            {isClientPage && companyLabel && (
                                              <Badge
                                                href='#'
                                                variant={`${companyLabel}-user badge-outlined py-1 mb-1`}
                                                style={{
                                                  display: 'inline-block',
                                                  marginLeft: '1rem',
                                                }}
                                              >
                                                {getLabelFromValue(defaultCompanyStatus, companyLabel)}
                                              </Badge>
                                            )}
                                            {isClientPage && isRolzoFirst ===true ? 
                                              <img
                                                src='/images/icons/rolzo-first-icon.png'
                                                alt=''
                                                className='important-booking-badge ml-2'
                                              >
                                              </img> : <></>}
                                            {isClientPage && (
                                              <img
                                                src={importantClient  ? '/images/icons/icon-important.png' : '/images/icons/icon-important-disabled.png'}
                                                id='important-client-icon'
                                                style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
                                                className='important-booking-badge'
                                                alt=''
                                              />
                                            )}
                                            </>
                                        )}
                                      </>
                                    )}
                                    <SmallDown>
                                      {!isMyProfile && subLink && (
                                        <a
                                          href={subLinkUrl || '#'}
                                          className='sub-link'
                                          onClick={onToggleUpdate}
                                        >
                                          {subLink}
                                        </a>
                                      )}
                                    </SmallDown>
                                  </>
                                ) : (
                                  <SmallUp>{title}</SmallUp>
                                )}
                                {!!isMyProfile && (
                                  <SmallDown>
                                    {subLink && (
                                      <a
                                        href={subLinkUrl || '#'}
                                        onClick={onToggleUpdate}
                                      >
                                        <div className='profile-change-password-link'>
                                          {subLink}
                                        </div>
                                      </a>
                                    )}
                                  </SmallDown>
                                )}
                                {!!showMobileTitle && (
                                  <SmallDown>
                                    <div
                                      className={
                                        subLink === 'Share'
                                          ? 'mobile-header-title-activity'
                                          : 'mobile-header-title'
                                      }
                                    >
                                      {title}
                                    </div>
                                    {subLink && (
                                      <a
                                        href={subLinkUrl || '#'}
                                        className='mobile-header-sub-link'
                                        style={{
                                          top: `-1px`,
                                        }}
                                        onClick={onToggleUpdate}
                                      >
                                        {!!isBookingView ? (
                                          <>
                                            {subLinkImg ? (
                                              <>
                                                {unreadMessages &&
                                                  unreadMessages > 0 ? (
                                                  <div className='mobile-header-sub-link-indecator'>
                                                    <div>
                                                      <img
                                                        className='supportbar-img'
                                                        src={subLinkImg}
                                                        alt=''
                                                      />
                                                    </div>
                                                    <div className='supportbar-unread-indicator' />
                                                    <div className='mobile-header-sub-linktext-with-indecator'>
                                                      {subLink}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <div
                                                      className={
                                                        subLink === 'Share'
                                                          ? 'mobile-hader-img-wrapper-share'
                                                          : 'mobile-hader-img-wrapper'
                                                      }
                                                    >
                                                      <img
                                                        src={subLinkImg}
                                                        alt=''
                                                      />
                                                    </div>

                                                    <div className='mobile-header-sub-linktext'>
                                                      {subLink}
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <div
                                                className={`${subLink === 'Follow' ||
                                                  subLink === 'Unfollow'
                                                  ? 'mobile-header-sub-link-textonly-golden'
                                                  : ''
                                                  } mobile-header-sub-link-textonly`}
                                              >
                                                {subLink}
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {subLink && (
                                              <a
                                                href={subLinkUrl || '#'}
                                                className='sub-link'
                                                onClick={onToggleUpdate}
                                              >
                                                {subLink}
                                              </a>
                                            )}
                                          </>
                                        )}
                                      </a>
                                    )}
                                  </SmallDown>
                                )}
                              </div>
                              {subTitleBadgeGrey || subTitleBadgeBlack ? (
                                <>
                                  {isMobileBrowser && (
                                    <SmallUp>
                                      <Badge
                                        variant={`${subTitleBadgeGrey ||
                                          subTitleBadgeBlack} badge-outlined ml-3 py-1 px-2`}
                                      >
                                        {getLabelFromValue(
                                          defaultBookingStatus,
                                          subTitleBadgeGrey || subTitleBadgeBlack
                                        ) === 'Quote received'
                                          ? 'Quote sent'
                                          : getLabelFromValue(
                                            defaultBookingStatus,
                                            subTitleBadgeGrey ||
                                            subTitleBadgeBlack
                                          )}
                                        {/* {subTitleBadgeGrey || subTitleBadgeBlack} */}
                                      </Badge>
                                    </SmallUp>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            renderStepper()
                          )}

                          {!!addPath && (
                            <Link to={addPath} className='add-btn'>
                              <img src='/images/icons/add-btn.svg' alt='' />
                            </Link>
                          )}
                          {!!addPathMapping && (
                            <Link
                              to={addPathMapping}
                              className='align-items-center add-link text-brown'
                            >
                              Vehicle Mapping
                            </Link>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {subtitle && <p className='edit-client-subtitle'>
                        {subtitle} 
                        <TooltipOnHover
                          delay={200}
                          placement='right-start'
                          isMobileBrowser={isMobileBrowser}
                          onMouseEnter={() => {}}
                          component={
                            <div>
                              The confidentiality of your personal data is of utmost importance to us. Our{' '}
                              <span>
                                <a
                                  href='https://rolzo.com/privacy-policy'
                                  style={{ color: '#a79779' }}
                                  target='_blank'
                                >
                                  Privacy Policy
                                </a>
                              </span>{' '}
                              describes the information we collect and how we use it to enhance your experience while using our services.
                            </div>
                          }
                        >
                          <img
                            className={'new-info-icon'}
                            // style={{ marginLeft: '12px' }}
                            src='/images/icons/new-info-icon.png'
                          />
                        </TooltipOnHover>
                      </p>}
                    </header>
                  </>
                }
              </Navbar.Brand>
              {false && !showBack && !showClose && !showStepper && (
                <Navbar.Toggle onClick={e => mobileSidebarToggle(e)} />
              )}
              <Navbar.Collapse></Navbar.Collapse>
            </Navbar>
          ) : (
            <></>
          )}
          {createdBy ? (
            <span className='createdBy-text'>
              {`Created by ${createdBy.name} on ${moment
                .utc(createdBy.createdAt)
                .format(' DD MMM YYYY')}`}
            </span>
          ) : (
            <></>
          )}
          <main
            className={`${hideSidebar ? 'hide-sidebar' : ''} ${
              noLeftPadding ? 'no-left-padding' : ''
            } ${noTitle ? 'no-title-main' : ''} ${
              showWarningBanner ? 'pt-0' : ''
            } ${
              removePageMargin && !isMobileBrowser
                ? isPayoutList
                  ? 'isAdminPayout'
                  : isRatesGrid
                  ? 'isRatesGrid'
                  : 'isAdminMain'
                : ''
            } ${isPayout ? 'payout-container' : ''} 
              ${
                title === 'Commissions' && isSettingsView ? 'no-top' : ''
              }                   
            `}
            style={
              isNewBooking && isSearchStep
                ? {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    minHeight: '100vh',
                    height: 'inherit',
                  }
                : isNewBooking && !isSearchStep
                ? { minHeight: '100vh', height: 'inherit' }
                : isSupplier || isClientPage
                ? { paddingLeft: '1.0625rem' }
                : isSupplierList || isAdminList
                ? { paddingLeft: '1.0625rem', paddingRight: '1.0625rem' }
                : {}
            }
          >
            {isNewEvent ? <CarouselComponent /> : <></>}
            {renderTabs()}
            {children}
            {isMasqueradeUser && <MasquradeBanner />}
          </main>
        </div>
      </>
    )
  }
)

const mapStateToProps = ({
  auth: {
    isLoggedIn,
    company,
    companyId,
    isMasqueradeUser,
    userId,
    pricingModel,
  },
}) => ({
  isLoggedIn,
  company,
  companyId,
  isMasqueradeUser,
  userId: userId,
  pricingModel,
})

export default withRouter(
  connect(mapStateToProps, { forwardRef: true, logout, removeFCMToken })(
    DefaultLayout
  )
)
